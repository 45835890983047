<template>
  <el-container class="not-found-container">
    <logo class="not-found-container__logo" />
    <logo-graphic class="not-found-container__graphic" />
    <div ref="card" class="actions">
      <el-alert
        v-if="auth.role !== 'operator'"
        :closable="false"
        title="Página não encontrada"
        type="error"
        center
        show-icon
      />
      <el-alert
        v-else
        :closable="false"
        title="Você não possuí permissão para acessar essa página"
        type="warning"
        center
        show-icon
      />
      <h4>Voltar para:</h4>
      <ul>
        <li v-if="auth && auth.permissions && auth.permissions.dashboard">
          <router-link :to="{ name: 'Dashboard' }" type="text">
            <i class="el-icon-menu" />
            Dashboard
          </router-link>
        </li>
        <li v-if="auth && auth.permissions && auth.permissions.sales">
          <router-link :to="{ name: 'Detalhes da venda' }" type="text">
            <i class="el-icon-goods" />
            Venda
          </router-link>
        </li>
        <li v-if="auth && auth.permissions && auth.permissions.payments">
          <router-link :to="{ name: 'Detalhes do recebimento' }" type="text">
            <i class="el-icon-sold-out" />
            Recebimento
          </router-link>
        </li>
        <li v-if="auth && auth.permissions && auth.permissions.add_clients">
          <router-link :to="{ name: 'Consulta Neopag - CPF' }" type="text">
            <i class="el-icon-circle-plus" />
            Adicionar cliente
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Clientes' }" type="text">
            <i class="el-icon-service" />
            Clientes
          </router-link>
        </li>
        <li v-if="auth && auth.permissions && auth.permissions.reports">
          <router-link :to="{ name: 'Relatórios' }" type="text">
            <i class="el-icon-document" />
            Relatórios
          </router-link>
        </li>
      </ul>
      <el-button class="button" type="primary" round @click="animateCard">
        <i class="el-icon-back" />
        Login
      </el-button>
    </div>
  </el-container>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from "@/components/Logo";
import LogoGraphic from "@/components/LogoGraphic";
import * as types from "@/store/types";
export default {
  components: {
    Logo,
    LogoGraphic
  },
  computed: {
    ...mapGetters({
      auth: types.AUTH_ACTIVE_STORE_DETAILS
    })
  },
  methods: {
    animateCard() {
      this.$refs.card.classList.add("--animate");
      setTimeout(() => {
        this.$router.push("/login");
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #79838a;

  h4 {
    margin: 16px 0;
  }

  &__logo {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2;

    @include small-down {
      right: calc(-50% + 20px);
      transform: translate(-100%, 0);
    }
  }

  &__graphic {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25%, -9%);
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  li {
    margin: 0 0 1rem;
    font-family: $title-font;
    list-style-type: none;
    a {
      font-weight: 500;
      color: $azul;
      text-decoration: none;
      transition: color 0.1s;
    }
    &:hover {
      a {
        color: $laranja;
      }
    }
  }
  li:last-of-type {
    margin: 0;
  }
  .actions {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 378px;
    padding: 16px;
    background: #fafafa;
    border-radius: 5px;
    animation-name: not-found;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-direction: forwards;

    @include box-shadow(1);
  }
  .button {
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 32px);
  }

  > * {
    transition: opacity 0.2s;
  }

  .--animate {
    transition: transform 1s;
    transform-style: preserve-3d;
    animation: rotate forwards;
    animation-duration: 0.5s;
    perspective: 800px;
    > * {
      opacity: 0;
    }
  }
}

@keyframes rotate {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(30px) rotateY(180deg);
  }
}
</style>
