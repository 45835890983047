<template>
  <svg
    :width="alternative ? width / 2 : width"
    :height="alternative ? height / 2 : height"
    viewBox="0 0 564 130">
    <defs>
      <linearGradient
        id="grd1"
        gradientUnits="userSpaceOnUse"
        x1="94.964"
        y1="72.173"
        x2="15.43"
        y2="39.408">
        <stop
          offset="0.209"
          stop-color="#f0cd72" />
        <stop
          offset="0.992"
          stop-color="#3f6f84" />
      </linearGradient>
      <linearGradient
        id="grd2"
        gradientUnits="userSpaceOnUse"
        x1="84.598"
        y1="53.835"
        x2="24.862"
        y2="24.186"
      >
        <stop
          offset="0.005"
          stop-color="#86bda5" />
        <stop
          offset="0.998"
          stop-color="#3f6f84" />
      </linearGradient>
      <linearGradient
        id="grd3"
        gradientUnits="userSpaceOnUse"
        x1="23.526"
        y1="108.306"
        x2="86.069"
        y2="88.982"
      >
        <stop
          offset="0"
          stop-color="#d4754a" />
        <stop
          offset="0.405"
          stop-color="#de966c" />
        <stop
          offset="1"
          stop-color="#f0cd72" />
      </linearGradient>
      <linearGradient
        id="grd4"
        gradientUnits="userSpaceOnUse"
        x1="79.975"
        y1="79.613"
        x2="9.717"
        y2="105.14"
      >
        <stop
          offset="0"
          stop-color="#86bda5" />
        <stop
          offset="1"
          stop-color="#d4754a" />
      </linearGradient>
    </defs>
    <path
      class="shp0"
      d="M97.23,66.68l-78.36,-32.28l-3.34,4.77l-1.19,6.21l78.36,32.28" />
    <path
      class="shp1"
      d="M97.58,64.1c3.87,-1.73 6.89,-4.85 8.51,-8.78c3.47,-8.4 -0.53,-18.03 -8.91,-21.48l-66.87,-27.55c-10.72,-4.41 -23.03,0.72 -27.44,11.43c-4.42,10.72 0.71,23.03 11.43,27.45l4.53,-10.98c-4.66,-1.93 -6.9,-7.28 -4.98,-11.94c1.93,-4.67 7.28,-6.9 11.95,-4.98l66.84,27.54c2.35,0.96 3.45,3.65 2.48,5.99c-0.45,1.08 -1.27,1.95 -2.32,2.44z" />
    <path
      class="shp2"
      d="M25.79,113.99l34.65,-14.42l32.41,-13.49c1.05,-0.49 1.87,-1.35 2.32,-2.43c0.97,-2.35 -0.14,-5.04 -2.46,-5.99l0.7,-5.16l3.82,-5.81c8.38,3.45 12.38,13.09 8.92,21.49c-1.62,3.92 -4.64,7.04 -8.51,8.77l-0.07,0.03l-32.29,13.44l-34.93,14.54c-5.18,2.15 -10.9,2.17 -16.09,0.03l4.52,-10.98c2.27,0.93 4.75,0.93 7.01,-0.01z" />
    <path
      class="shp3"
      d="M14.18,86.13l31.23,-12.98v0l47.43,-19.71l3.07,5.28l1.71,5.58l-47.66,19.81l-31.22,12.98c-2.26,0.94 -4.01,2.7 -4.94,4.96c-0.93,2.26 -0.92,4.74 0.01,7c0.94,2.26 2.71,4.02 4.96,4.94l-1.53,5.44l-2.99,5.54c-5.19,-2.14 -9.24,-6.18 -11.4,-11.35c-2.16,-5.19 -2.18,-10.9 -0.03,-16.1c2.14,-5.18 6.16,-9.24 11.35,-11.4z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M383.73,39.65c4.28,2.54 7.64,6.12 10.07,10.73c2.43,4.61 3.65,9.97 3.65,16.09c0,6.12 -1.22,11.49 -3.65,16.1c-2.44,4.61 -5.79,8.18 -10.07,10.73c-4.28,2.54 -9.02,3.81 -14.24,3.81c-7.17,0 -12.8,-2.39 -16.9,-7.19v27.76h-16.27v-80.97h15.55v6.86c4.03,-5.16 9.91,-7.74 17.63,-7.74c5.22,0 9.96,1.27 14.24,3.82zM376.95,78.47c2.68,-2.99 4.02,-6.99 4.02,-12c0,-5.01 -1.34,-9.02 -4.02,-12c-2.68,-2.99 -6.11,-4.48 -10.28,-4.48c-4.17,0 -7.6,1.49 -10.27,4.48c-2.68,2.98 -4.02,6.99 -4.02,12c0,5.02 1.34,9.01 4.02,12c2.68,2.99 6.1,4.48 10.27,4.48c4.17,0 7.59,-1.5 10.28,-4.48z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M187.55,42.02c4.28,4.42 6.42,10.99 6.42,19.69v34.07h-16.28v-31.41c0,-4.72 -0.98,-8.24 -2.92,-10.56c-1.95,-2.32 -4.77,-3.48 -8.45,-3.48c-4.1,0 -7.37,1.34 -9.81,4.04c-2.43,2.69 -3.65,6.69 -3.65,12v29.42h-16.27v-59.51h15.55v6.97c2.15,-2.5 4.83,-4.44 8.03,-5.81c3.19,-1.36 6.71,-2.04 10.53,-2.04c6.95,0 12.57,2.21 16.85,6.63z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M260.1,70.89h-42.47c0.76,3.69 2.57,6.6 5.43,8.74c2.85,2.14 6.4,3.21 10.64,3.21c2.92,0 5.51,-0.46 7.77,-1.38c2.26,-0.92 4.37,-2.37 6.31,-4.37l8.66,9.96c-5.29,6.42 -13.01,9.62 -23.16,9.62c-6.33,0 -11.93,-1.31 -16.8,-3.93c-4.87,-2.61 -8.63,-6.25 -11.27,-10.9c-2.64,-4.64 -3.96,-9.92 -3.96,-15.82c0,-5.82 1.31,-11.08 3.91,-15.76c2.61,-4.68 6.19,-8.33 10.75,-10.95c4.55,-2.62 9.65,-3.92 15.28,-3.92c5.49,0 10.47,1.25 14.92,3.76c4.45,2.51 7.95,6.1 10.49,10.78c2.54,4.69 3.81,10.12 3.81,16.31c0,0.22 -0.1,1.78 -0.31,4.65zM222.01,51.76c-2.51,2.21 -4.03,5.24 -4.59,9.07h27.65c-0.56,-3.76 -2.09,-6.76 -4.6,-9.01c-2.5,-2.25 -5.56,-3.37 -9.18,-3.37c-3.68,0 -6.78,1.11 -9.28,3.32z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M281.02,92.74c-4.7,-2.61 -8.36,-6.25 -11.01,-10.9c-2.65,-4.64 -3.97,-9.92 -3.97,-15.82c0,-5.9 1.32,-11.18 3.97,-15.81c2.64,-4.65 6.31,-8.28 11.01,-10.9c4.7,-2.62 10,-3.92 15.91,-3.92c5.91,0 11.2,1.31 15.86,3.92c4.65,2.62 8.31,6.25 10.96,10.9c2.64,4.64 3.96,9.92 3.96,15.81c0,5.9 -1.32,11.17 -3.96,15.82c-2.65,4.65 -6.3,8.28 -10.96,10.9c-4.66,2.62 -9.95,3.93 -15.86,3.93c-5.91,0 -11.22,-1.31 -15.91,-3.93zM307.21,78.03c2.68,-2.99 4.02,-6.99 4.02,-12c0,-5.02 -1.34,-9.02 -4.02,-12c-2.68,-2.99 -6.11,-4.48 -10.28,-4.48c-4.17,0 -7.62,1.49 -10.33,4.48c-2.71,2.99 -4.07,6.99 -4.07,12c0,5.02 1.36,9.02 4.07,12c2.72,2.99 6.16,4.48 10.33,4.48c4.17,0 7.6,-1.49 10.28,-4.48z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M543.35,94.87c-1.76,-0.98 -3.12,-2.33 -4.11,-4.06c-0.99,-1.73 -1.48,-3.7 -1.48,-5.9c0,-2.2 0.49,-4.17 1.48,-5.9c0.99,-1.73 2.35,-3.09 4.11,-4.06c1.75,-0.98 3.73,-1.47 5.93,-1.47c2.21,0 4.18,0.49 5.92,1.47c1.73,0.98 3.1,2.33 4.08,4.06c0.98,1.73 1.48,3.7 1.48,5.9c0,2.2 -0.5,4.17 -1.48,5.9c-0.98,1.73 -2.35,3.09 -4.08,4.06c-1.74,0.98 -3.71,1.47 -5.92,1.47c-2.2,0 -4.18,-0.49 -5.93,-1.47z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M448.75,41.97c4.66,4.39 6.99,11.01 6.99,19.86v33.96h-15.24v-7.41c-3.06,5.53 -8.76,8.3 -17.11,8.3c-4.31,0 -8.05,-0.77 -11.22,-2.32c-3.17,-1.55 -5.58,-3.69 -7.25,-6.42c-1.67,-2.73 -2.5,-5.83 -2.5,-9.29c0,-5.53 1.96,-9.88 5.9,-13.06c3.93,-3.17 10,-4.76 18.21,-4.76h12.94c0,-3.76 -1.08,-6.65 -3.23,-8.68c-2.16,-2.02 -5.4,-3.04 -9.71,-3.04c-2.99,0 -5.93,0.5 -8.81,1.49c-2.89,1 -5.34,2.34 -7.36,4.03l-5.85,-12.05c3.06,-2.28 6.73,-4.06 11.01,-5.31c4.28,-1.25 8.68,-1.88 13.2,-1.88c8.69,0 15.37,2.19 20.03,6.58zM434.88,83c2.15,-1.36 3.68,-3.37 4.59,-6.03v-6.08h-11.16c-6.68,0 -10.02,2.32 -10.02,6.97c0,2.21 0.81,3.96 2.45,5.25c1.63,1.29 3.88,1.94 6.73,1.94c2.79,0 5.25,-0.68 7.41,-2.05z" />
    <path
      v-if="!isMobile"
      :class="alternative ? '--alt' : ''"
      class="shp4"
      d="M524.87,36.27v49.55c0,10.91 -2.68,19.02 -8.04,24.34c-5.36,5.31 -13.19,7.96 -23.48,7.96c-5.42,0 -10.58,-0.7 -15.44,-2.11c-4.87,-1.4 -8.9,-3.43 -12.1,-6.08l6.47,-12.39c2.37,2.06 5.36,3.71 8.98,4.92c3.62,1.22 7.23,1.82 10.85,1.82c5.64,0 9.79,-1.35 12.47,-4.04c2.68,-2.69 4.02,-6.76 4.02,-12.23v-2.54c-4.25,4.94 -10.16,7.41 -17.74,7.41c-5.15,0 -9.87,-1.2 -14.14,-3.59c-4.28,-2.4 -7.67,-5.77 -10.18,-10.12c-2.5,-4.35 -3.76,-9.37 -3.76,-15.04c0,-5.68 1.25,-10.69 3.76,-15.04c2.51,-4.35 5.9,-7.73 10.18,-10.12c4.27,-2.39 8.99,-3.59 14.14,-3.59c8.14,0 14.33,2.83 18.57,8.51v-7.63zM504.67,74.71c2.74,-2.69 4.12,-6.21 4.12,-10.56c0,-4.35 -1.38,-7.87 -4.12,-10.56c-2.75,-2.69 -6.28,-4.03 -10.59,-4.03c-4.31,0 -7.86,1.35 -10.65,4.03c-2.78,2.69 -4.18,6.21 -4.18,10.56c0,4.35 1.4,7.87 4.18,10.56c2.79,2.69 6.33,4.03 10.65,4.03c4.31,0 7.84,-1.34 10.59,-4.03z" />
  </svg>
</template>

<script>
export default {
  props: {
    alternative: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "242"
    },
    height: {
      type: String,
      default: "65"
    },
    isMobile: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style>
.shp0 {
  fill: url(#grd1);
}
.shp1 {
  fill: url(#grd2);
}
.shp2 {
  fill: url(#grd3);
}
.shp3 {
  fill: url(#grd4);
}
.shp4 {
  fill: #fff;
}
.shp4.--alt {
  fill: #6c737c;
}
</style>
